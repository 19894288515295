$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.badge {
  color: #fff;
  background-color: #A5AEBE;
  padding: 2px 8px;
  border-radius: 4px;
}

.icon {
  width: 24px;
  height: 24px;
  color: $red !important;
}


.tableTitleCell {
  width: 300px;
  font-weight: $bold-weight;
  color: $darkBlue;
  margin-bottom: 4px;
}

@media (max-width: 1000px) {
  .tableTitleCell {
    width: 100%;
  }
}

.download {
  color: $green;
  font-weight: 700;
  font-size: 14px;
}

.remove {
  color: $red;
  font-weight: 700;
  font-size: 14px;
}

.table {
  width: max-content;
  min-width: 100%;
  table-layout: auto;
}

.tableWrapper {
  overflow-x: auto;
  overflow-y: auto;
  width: calc(100vw - 350px);
}

.step5status {
  &__DOC_CUSTOMER_SIGNED, &__DOC_EXECUTOR_SIGNED {
    color: $green;
  }

  &__DOC_SIGN_WAITING, &__DOC_EXECUTOR_SIGN_WAIT {
    color: #A5AEBE
  }
}