//TODO: Must be removed after refactoring
@import './scss/home';
@import './scss/slider';

//монохромный стиль в случае траура
//html {
//	filter: grayscale(100%);
//}


* {
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 14px;
}

button {
	border: 0;
	background: transparent;
	cursor: pointer;
}

a {
	text-underline: none;
	text-decoration: none;
}

input, textarea {
	outline: none;
}

ul {
	li {
		list-style: none;
	}
}

.scroll {
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px $whiteBlue;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $grey-100;
    border-radius: 50px;
	}
}

.scrollHorizontal{
	&::-webkit-scrollbar {
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px $whiteBlue;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $grey-100;
		border-radius: 50px;
	}
}

.mb-0 {
  margin-bottom: 0;
}

.mb-32 {
	margin-bottom: 32px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-32 {
	margin-top: 32px;
}

.mb-24 {
	margin-bottom: 24px;
}

.mt-64 {
	margin-top: 64px;
}

.terms {
	height: 100%;
	padding-top: 112px;
	h1 {
		color: $darkBlue;
		text-align: center;
	}
	p {
		margin-top: 24px;
	}
}

.input__label {
	font-weight: 500;
	font-size: 14px;
	line-height: 110%;
	color: #1d293f;
	margin-bottom: 8px;
}
.flex {
	display: flex;
	flex-wrap: wrap;
}
.flex-column {
	flex-direction: column;
}
.enbek-system {
	display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.width-100 {
	width: 100%;
}
.align {
	&-end {
		align-items: flex-end;
	}
	&-center {
		align-items: center;
	}
}
.justify {
	&-center {
		justify-content: center;
	}
	&-end {
		justify-content: flex-end;
	}
}
.right {
	justify-content: flex-end;
}
.pt {
	&-1 {
		padding-top: 1em;
	}
}
.pl {
	&-1 {
		padding-left: 1em;
	}
}
.pr {
	&-1 {
		padding-right: 1em;
	}
}
.border {
	&-1 {
		padding: 15px;
		border: 1px solid #1d293f31;
		border-radius: 5px;
	}
}
.bg-dark {
	background-color: #1d293fa9;
	grid-gap: 16px;
	display: flex;
}
.upper {
	text-transform: uppercase;
}
.tag {
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	line-height: 110%;
	padding: 4px 8px;
	background: #aab8d1;
	border-radius: 4px;
	color: #ffffff;
}
.container {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.container-banners {
	max-width: 980px;
	height: 339px;
	width: 100%;
	margin: 130px auto 0;
	position: relative;
}

.container-footer {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}
@media screen and (max-width: 768px) {
	.container-banners {
		max-width: 690px;
		width: 90%;
		height: 380px;
		margin: 30px auto 0;
	}
}

@media screen and (max-width: 700px) {
	.container-banners {
		max-width: 640px;
		width: 90%;
	}
}

.container-form {
	max-width: 1319px;
	height: 107.78px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.td--center {
	text-align: center;
	vertical-align: middle;

	label {
		display: inline-block;
	}
}
.modal-opened {
	@media (max-width: 1210px) {
		header {
			position: fixed;
			top: 0;
			width: 100%;
			box-sizing: border-box;
			z-index: 2;
		}
	}
}
.customSelect {
	max-width: 240px;
	position: relative;
	&-menu {
		display: none;
		&.active {
			display: block;
			position: absolute;
			z-index: 999;
			left: -30px;
		}
		margin-top: 10px;
		list-style: none;
		background: #ffffff;
		box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
		border-radius: 4px;
		li {
			display: flex;
			align-items: center;
			img {
				margin-right: 15px;
			}
			font-weight: normal;
			font-size: 14px;
			line-height: 120%;
			padding: 12px 16px;
			color: #aab8d1;
			cursor: pointer;
			&:hover {
				background: #f9fbff;
			}
		}
	}
	&-current {
		position: relative;
		padding: 0 8px;
		background: #f9fbff;
		border: 1px solid #d3dff0;
		box-sizing: border-box;
		border-radius: 4px;
		height: 28px;
		display: flex;
		align-items: center;
		p {
			cursor: pointer;
		}
		span {
			top: 60%;
			right: 17px;
			position: absolute;
			display: block;
			transform: rotate(-90deg);
			&::after,
			&::before {
				position: absolute;
				content: '';
				display: block;
				width: 6px;
				height: 2px;
				background: #aab8d1;
			}
			&::after {
				transform: rotate(-45deg);
			}
			&::before {
				top: 4px;
				transform: rotate(45deg);
			}
		}
		p {
			font-size: 14px;
			color: #aab8d1;
		}
	}
}
.container-mobile {
	margin: 0px 15px;
}

.input-file {
	margin-right: 0;
	input {
		width: 0;
		height: 0;
		opacity: 0;
	}
	.custom-file {
		cursor: pointer;
		align-items: center;
		padding: 8px 14px 8px 8px;
		border: 1px solid $green;
		border-radius: 2px;
		transition: 0.2s;
		img {
			margin-right: 8px;
		}
		span {
			color: $green;
			font-weight: 500 !important;
			font-size: 14px !important;
			line-height: 110% !important;
			margin: 0 !important;
			padding: 0 !important;
		}
		&:hover {
			transition: 0.2s;
			background: $darkBlue;
		}
	}
}

.title {
	font-family: 'Montserrat Alternates';
	font-weight: bold;
	font-size: 42px;
	line-height: 64px;
	color: $darkBlue;
}

#root {
	overflow: hidden;
}

.traditional-input {
	margin: 0;
	font-weight: 500;
	font-size: 14px;
	color: #1d293f;
	input {
		font-family: 'Inter';
		display: block;
		background: #ffffff;
		border: 1px solid #d3dff0;
		box-sizing: border-box;
		border-radius: 4px;
		max-width: 296px;
		width: 100%;
		outline: none;
		padding: 10.5px 8px;
		line-height: 120%;
		&:disabled {
			background: #f9fbff;
			border: 1px solid #d3dff0;
			box-sizing: border-box;
			border-radius: 4px;
		}
		&::placeholder {
			font-size: 14px;
			line-height: 120%;
			color: #aab8d1;
			opacity: 1 !important;
		}
		&:focus {
			border-color: #1d293f;
		}
	}
}

.traditional-checkbox {
	margin: 0;
	position: relative;
	input {
		position: absolute;
		height: 0px;
		z-index: -1;
	}
	input:disabled + span {
		opacity: 0.5;
	}
	input:checked + span {
		background: $green;
		border: 1px solid rgba(255, 255, 255, 0);
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 6px;
			left: 5px;
			display: block;
			width: 10px;
			height: 2px;
			background: #fff;
			transform: rotate(-51deg);
		}
		&::before {
			content: '';
			position: absolute;
			top: 9px;
			left: 2px;
			display: block;
			width: 6px;
			height: 2px;
			background: #fff;
			transform: rotate(41deg);
		}
	}
	span {
		display: block;
		width: 16px;
		height: 16px;
		border: 1px solid #c6cdd6;
		border-radius: 5px;
	}
	&--dist {
		display: flex;
		p {
			margin-left: 8px !important;
		}
	}
}

.custom-datepicker {
	input {
		padding: 21px;
		width: 100%;
	}
}

.datepicker {
	padding: 20px;
	// border: 1px solid red;
	background: #fff;
	ul {
		list-style: none;
	}
	&-head {
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				padding: 16px;
			}
		}
	}
	&-body {
		-webkit-filter: grayscale(100%);
		ul {
			display: none;
			&.active {
				display: flex;
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
			a {
				width: 32px;
				height: 32px;
				width: 100%;
				padding: 16px;
				display: block;
			}
		}
	}
	&.menu {
		width: 100%;
	}
	input {
		display: none;
	}
}

.greenWhiteBtn {
	a {
		background: none;
		border: 2px solid $green;
		color: $green;
	}
}

.noBg {
	a {
		background: none;
		border: none;
		color: #aab8d1;
		transition: 0.2s;
		margin: 0;
	}
}
.modal-delete {
	.modal-close-wrapper {
		max-width: 452px;
		width: calc(100% - 32px);
		.modal-alert__title {
			text-align: center;
		}
		.modal-alert__wrapper {
			padding-bottom: 30px;
		}
		.modal-alert__title p {
			font-weight: 700;
		}
		.modal-alert__title span {
			padding: 16px 0 0;
		}
		.modal-alert__title span.accent {
			padding: 0;
			font-weight: 700;
		}
		.greenBtn {
			margin: 24px auto 0;
			& a {
				padding: 12px 22px;
				margin: 0;
			}
			&.noBg a {
				padding: 0;
				font-weight: 700;
			}
		}
	}
	.modal-alert__btn {
		display: block !important;
		.greenBtn {
			margin: 30px auto 0;
			text-align: center;
		}
	}
}
.modal-alert {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #5b688057;
	z-index: 999;
	display: none;
	&.active {
		display: block;
	}
	&__wrapper {
		position: relative;
		padding: 40px;
		@media (max-width: 768px) {
			padding: 56px 16px 32px;
		}
	}
	.modal-close-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		.close {
			cursor: pointer;
			position: absolute;
			display: block;
			top: 20px;
			right: 25px;
			z-index: 999;
			&::after,
			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 15px;
				height: 2px;
				background: #5b6880;
			}
			&::after {
				transform: rotate(-45deg);
			}
			&::before {
				transform: rotate(45deg);
			}
		}
	}
}
.modal-close-wrapper {
	position: relative;
}
.modal-sign {
	&__title {
		font-size: 24px;
		line-height: 130%;
		color: #1d293f;
	}
	&__span {
		font-size: 16px;
		line-height: 140%;
		color: #5b6880;
		padding: 35px 0px;
		display: block;
	}
	.modal-alert__btn {
		margin: 15px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.modal-delete {
	.modal-close-wrapper {
		max-width: 452px;
		.modal-alert__title {
			text-align: center;
		}
	}
	.modal-alert__btn {
		display: block !important;
		margin: 0;
		.greenBtn {
			text-align: center;
			max-width: none;
		}
	}

	& > span {
		padding: 0;
		padding-top: 16px;
		margin-bottom: 24px;
	}
	p {
		font-weight: bold;
		font-size: 24px;
		@media (max-width: 768px) {
			font-size: 18px;
			line-height: 130%;
		}
	}

	.doc-name {
		display: block;
		padding: 0;
		font-weight: bold;
	}
	.btn-nofill {
		margin-top: 24px;
	}
	.btn-nofill a {
		padding: 0;
	}
}

.hidden-input {
	position: absolute;
	z-index: -1;
	height: 0;
	width: 0;
	left: -100vw;
}

.react-tag-input {
	border: 1px solid #d3dff0 !important;
}
.react-tag-input__input:focus .react-tag-input {
	border: 1px solid red !important;
}
.react-tag-input__input {
	max-width: none !important;
	width: auto !important;
	border: none !important;
	font-size: 14px;
}
.react-tag-input__input::placeholder {
	color: #aab8d1;
}

.react-tag-input__tag {
	background: #aab8d1;
	border-radius: 4px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
}
.react-tag-input__tag__remove {
	background: #aab8d1;
	color: #fff;
}
.react-tag-input__tag__remove::before,
.react-tag-input__tag__remove::after {
	background-color: #fff;
}
.react-tag-input__tag__content {
	padding: 4px 5px 6px 8px;
}

.SelectStatus {
	position: relative;
	&__input {
		position: relative;
		cursor: pointer;
		input {
			padding-left: 8px;
			padding-right: 8px;
			pointer-events: none;
		}
		svg {
			position: absolute;
			right: 11px;
			fill: #aab8d1;
			top: 9px;
		}
	}

	&__text {
		color: #aab8d1;
		font-weight: normal;
		font-size: 12px;
		margin-left: 10px;
		margin-right: 10px;
		position: absolute;
		top: 52%;
		transform: translateY(-50%);
		box-sizing: border-box;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 70%;
		overflow: hidden;
	}
	&__dropdown {
		z-index: 9;
		box-sizing: border-box;
		position: absolute;
		right: 0;
		padding: 16px 8px;
		background-color: #fff;
		box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
		border-radius: 4px;
		margin-top: 10px;
		&::before {
			content: '';
			display: block;
			border-top: 16px solid #fff;
			border-bottom: 16px solid transparent;
			border-left: 16px solid transparent;
			position: absolute;
			right: 7px;
			top: -6px;
			transform: rotate(-45deg);
		}
		&__item {
			display: flex;
			align-items: center;
			margin-bottom: 8px !important;
			cursor: pointer;
			margin-right: 0;
			label {
				margin-right: 8px;
				margin-bottom: 0;
			}
			p {
				font-size: 12px;
				color: #5b6880;
				font-weight: normal;
				margin: 0 !important;
				padding: 0 !important;
			}
			input {
				left: -100vw;
			}
		}
		.greenBtn a {
			padding: 9px 10px;
			font-size: 14px;
		}
		.btn-nofill {
			margin-right: 6px;
			font-weight: bold;
			font-size: 14px;
			a {
				padding-left: 0;
			}
		}
		.traditional-checkbox span {
			border: 1px solid #dbe8ff;
		}
		.grid-end {
			margin-top: 16px;
		}
	}
}

.heading2 {
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 31px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(91, 104, 128, 1);

	&-withbtn {
		display: flex;
		align-items: center;

		button {
			width: auto;
			margin-left: 32px;
		}

		@media (max-width: 720px) {
			flex-direction: column;
			align-items: flex-start;
			button {
				margin-top: 24px;
				margin-left: 0;
			}
		}
	}

	@media (max-width: 720px) {
		margin-bottom: 16px;
	}
}

table.content-table {
	gap: 30px;
	border-collapse: separate;
	border-spacing: 30px 8px;
	margin-left: -30px;
	td {
		font-size: 14px;
		line-height: 110%;
		color: #1d293f;
		vertical-align: text-top;

		&:first-child {
			font-weight: 500;
		}
		&:last-child {
			color: #5b6880;
		}
	}
	@media (max-width: 720px) {
		border-spacing: 30px 18px;

		tr {
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;

			td {
				margin-bottom: 4px;
			}
		}
	}
}

.profile {
	table {
		gap: 30px;
		border-collapse: separate;
		border-spacing: 30px 8px;
		margin: 32px 0 64px -32px;
		td {
			font-size: 14px;
			line-height: 110%;
			color: #1d293f;
			&:first-child {
				font-weight: 500;
			}
			&:last-child {
				color: #5b6880;
			}
		}
		@media (max-width: 720px) {
			border-spacing: 30px 18px;
		}
	}
	.logoTitle {
		font-size: 14px;
		line-height: 110%;
		font-weight: 500;
		color: #1d293f;
		margin-right: 18px;
	}
	&__inputs-row {
		display: flex;
		margin-top: 32px;
		margin-bottom: 64px;
		align-items: end;

		@media (max-width: 720px) {
			flex-direction: column;
			margin-top: 16px;
			margin-bottom: 32px;
			label.traditional-input {
				width: 100%;
				max-width: 100%;
				margin-bottom: 16px;
			}
		}

		.traditional-input input {
			max-width: 100%;
			width: 100%;
			padding-left: 16px;
			& + div {
				margin-top: 8px;
			}
		}
		label {
			max-width: 385px;
			width: 100%;
			margin-right: 32px;

			p {
				padding-bottom: 8px;
			}
		}
	}

	@media (max-width: 720px) {
		table {
			margin-top: 16px;
			margin-bottom: 16px;
			td {
				display: block;
				margin-bottom: 4px;
			}
			tr {
				margin-bottom: 16px;
			}
		}
	}
}

.with-img {
	display: flex;
}

.css-1pahdxg-control {
	box-shadow: none !important;
	height: 40px;
}
.css-c8odyh-control {
	.css-jsdavj-ValueContainer,
	.css-dz39rr-ValueContainer {
		height: 40px;
	}
}
.css-113nnzh-control {
	border-color: #d3dff0 !important;
}
.css-dz39rr-ValueContainer {
	border: 1px solid #d3dff0 !important;
	height: 40px !important;
	.css-jsdavj-ValueContainer,
	.css-dz39rr-ValueContainer {
		height: 40px !important;
	}
}

.file-list {
	list-style: none;
	li {
		font-size: 16px;
		line-height: 110%;
		color: #5b6880;
		padding: 11px 16px;
		display: flex;
		justify-content: space-between;
		padding-left: 0;
		img {
			margin-right: 10px;
			margin-bottom: -2px;
		}
	}
	&__delete {
		font-weight: bold;
		font-size: 14px;
		line-height: 120%;
		color: #eb5757;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-left: 15px;
		img {
			margin-right: 8px;
		}
	}
}

.lang-select {
	cursor: pointer;
	list-style: none;
	img {
		margin-left: 8px;
	}
	position: relative;
	&__dropdown {
		position: absolute;
		top: 45px;
	}
	&-dark {
		.lang-select__dropdown {
			top: 30px !important;
		}
	}
}
.rc-tabs-ink-bar,
.rc-tabs-dropdown,
.rc-tabs-nav-operations {
	display: none;
}
.rc-tabs-top {
	width: 100%;
}
.rc-tabs-nav-list {
	display: flex;
}
.rc-tabs-nav-wrap {
	border-bottom: 1px solid #d3dff0;
}
.rc-tabs-tab-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 110%;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	padding: 12px 40px;
	cursor: pointer;
	color: #aab8d1;
	outline: none;

	@media (max-width: 720px) {
		font-size: 12px;
	}
}
.rc-tabs-tab-active {
	border-bottom: 1px solid #1d293f;
	.rc-tabs-tab-btn {
		color: #1d293f;
	}
}
.rc-tabs-tabpane {
	outline: none;
}

.absolute-header {
	position: absolute;
	top: 25px;
	margin: 10px auto;
	z-index: 1;
	left: 50%;
	transform: translateX(-50%);

	@media (max-width: 1210px) {
		width: 100% !important;
	}
	@media (max-width: 425px) {
		.marginTop {
			height: 60px;
			margin-top: 60px;
		}
	}
}

//Notification
:root {
	--toastify-toast-width: 600px;
	--toastify-text-color-light: #fff;
	--magic-number: 0.08749; /* tan(5°)/2 */
	--employer-wrapper-with: 100vw;
	--skew-padding: calc(var(--employer-wrapper-with) * var(--magic-number));
}

@media screen and (min-width: 42em) {
	:root {
		--employer-wrapper-width: 42em;
	}
}

.Toastify__toast-container--top-center {
	top: 0;
}
.Toastify__toast {
	border-radius: 0;
}
.Toastify__toast-icon {
	display: none;
}
.Toastify__toast--success {
	background: #17b67c;
}
.Toastify__toast--error {
	background: #e65332;
}
.Toastify__close {
	position: absolute;
	top: 50%;
	right: 35px;
	transform: translateY(-50%);
}
.Toastify__toast-body {
	padding: 18px 80px;
	text-align: center;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
}
.internationalization {
	p {
		font-family: "Inter Medium", sans-serif;
		font-size: 16px;
		font-weight: 500;
		line-height: 110%;
		cursor: pointer;
		color: white;
	}
	p:hover {
		color: $green;
	}
	&-dark {
		p {
			font-family: "Inter Medium", sans-serif;
			font-size: 16px;
			font-weight: 500;
			line-height: 110%;
			cursor: pointer;
			color: black;
		}
		p:hover {
			color: $green;
		}
	}
}

.green-fill {
	fill: $green;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
	background-color: $green !important;
	border-color: $green !important;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
	border-color: $green !important;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after{
	background-color: $green !important;
}