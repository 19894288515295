$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.button {
	background: $green;
	padding: 11px 22px;
	color: $white;
	font-size: 16px;
	font-weight: $middle-weight;
	transition: 0.2s linear;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		opacity: 0.5;
	}
	&:disabled {
		opacity: 0.5;
	}
}

.transparent {
	background: transparent;
	color: $green;
}

.border {
	border: 1px solid $green;
}

.icon {
	margin-right: 9px;
	display: flex;
	align-items: center;

	.bigSize {
		width: 21px;
	}

	.defaultSize {
		width: 14px;
	}

	.smallSize {
		width: 12px;
	}
}

.bare {
	color: $whiteBlue;
	font-weight: $bold-weight;
}
.bare__disabled {
	opacity: 0.5;
	cursor: default;
}

.big {
	font-size: 16px;
}

.default {
	font-size: 14px;
}

.small {
	font-size: 12px;
}

.defaultBorder {
	border-color: $green;
}

.greyBorder {
	border-color: $grey-100;
}

.redBorder {
	border-color: $red;
}

.defaultText {
	color: $green;
}

.greyText {
	color: $grey-100;
}

.hidden {
	display: none;
}

.backgroundGreen {
	background-color: $green;
}

.backgroundWhite {
	background-color: $white;
}

.backgroundRed {
	background-color: $red;
}
