$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Чтобы не показывать выходящие элементы */
}

/* Градиентный фон на дальнем плане */
.bgGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2; /* Фон позади всех элементов */
  background: linear-gradient(to top, #147B55, #17B67C); /* Градиент снизу вверх */
}

/* Canvas между фоном и контентом */
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* На переднем плане относительно градиента */
  pointer-events: none; /* Canvas не блокирует взаимодействия с контентом */
}

/* Контент (всегда сверху) */
.content {
  position: relative; /* Относительное позиционирование для слоёв */
  z-index: 0; /* Поверх canvas */
  display: flex;
  justify-content: space-between; /* Разделение на два блока */
  height: 100%;
}

/* Стили для левой и правой областей */
.left {
  margin-left: 32px;
  flex: 1; /* Распределение пространства в соответствии с остальными элементами */
}

.right {
  flex: 1;
}

/*
.backBlock {
  margin: 56px 0 36px 0;
}*/

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column
  }
  .left {
    flex: none;
    margin-bottom: 16px;
  }
  .right {
    flex: none;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 720px) {
  .left {
    margin-bottom: 0;
  }
  .right {
    margin-bottom: 0;
  }
}