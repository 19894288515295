$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.dropzoneContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Arial, sans-serif;
}

.dropzone {
  display: flex;
  justify-content: flex-start;
  border: 2px dashed #cce7d0;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  background-color: #f9f9f9;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;

  &.active {
    border-color: #6cc76d;
    background-color: #e6ffe6;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }

  .formats {
    font-size: 14px;
    color: #666;
  }

  .chooseFileBtn {
    margin-left: auto;
    transition: background-color 0.3s;
  }
}

.icon {
  margin-right: 12px;
}

.filePreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px 20px;
  background-color: #f9f9f9;

  .fileInfo {
    font-size: 16px;
    color: #333;
  }

  .removeBtn {
    display: flex;
    align-items: center;
    color: #ff6b6b;
    transition: background-color 0.3s;
  }
}

.iconRed {
  width: 16px;
  height: 16px;
  @extend .icon;
  fill: $red;
}

.errorMessage {
  color: #ff6b6b;
  font-size: 14px;
  margin-top: 8px;
}